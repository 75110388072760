<template>
  <div class="shadow">
    <b-card>
      <div
        v-if="!show"
        class="d-flex justify-content-between"
      >
        <div>
          <h4>{{ client.title }}</h4>
          <h4>{{ client.text }}</h4>
        </div>
        <div>
          <component
            :is="client.icon"
            :style="'color:'+client.color"
            class="h2 "
          />
        </div>
      </div>
      <div
        v-if="show"
        class="text-center"
      >
        <div class="text-center">
          <component
            :is="client.icon"
            class="h2 "
            :style="'color:'+client.color"
          />
        </div>
        <h3>{{ client.title }}</h3>
        <h2>{{ client.text }}</h2>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
  props: {
    client: {
      type: Object,
      default: () => ({
        title: '',
        text: '',
        icon: '',
        color: '',
      }),
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
